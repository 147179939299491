import React, { useEffect, useState } from "react"
import "./eventsTopSection.scss"
import Link from "gatsby-link"
import { GatsbyImage } from "gatsby-plugin-image"
import TruncateMarkup from "react-truncate-markup"
import Card from "react-bootstrap/Card"
import Slider from "react-slick"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"

const sanitizePath = (name) => {
  return name
    .replace(/[<>:"\/\\|?*]/g, "_")
    .replace(/ /g, "-")
    .toLowerCase()
}

const EventsCards = ({ data }) => {
  const [isHydrated, setIsHydrated] = useState(false)

  // Force a re-render after the component mounts
  useEffect(() => {
    setIsHydrated(true) // This will cause a re-render
  }, [])
  const getSettings = (right, left) => {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: right,
      prevArrow: left,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return settings
  }

  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />
  )
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }, [])
  if (!isHydrated) {
    return null // Don't render until the component is hydrated
  }
  return (
    <div className="eventsTopSectionWrapper">
      <div className="eventsCardSection">
        <h1>Join an AMA Session</h1>
        <p className="eventsCardSectionDesc">
          It's good to have questions. But even better to know the answers!
        </p>
        <div className="eventsCardSectionCarouselWrapper">
          <Slider {...settings}>
            {data?.map((card, index) => {
              let currEvent = card?.node
              const sanitizedPath = sanitizePath(currEvent?.name)
              return (
                <a
                  className="eventsCardSectionCard"
                  href={`https://${currEvent.eventLink}`}
                  key={currEvent.name}
                >
                  <div className="eventsCardSectionCardImg">
                    {currEvent.previewImg &&
                      currEvent.previewImg.gatsbyImageData && (
                        <GatsbyImage
                          image={currEvent.previewImg.gatsbyImageData}
                          title={currEvent.name}
                        />
                      )}
                  </div>
                  <div className="eventsCardSectionCardBody">
                    <h3 className="cardHeading">Ask Ali Anything!</h3>
                    <p className="eventsCardSectionCardBodyDate">
                      {currEvent?.eventDate}{" "}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: currEvent.descriptionA.childMarkdownRemark.html,
                      }}
                      className="region"
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: currEvent.descriptionB.childMarkdownRemark.html,
                      }}
                      className="time"
                    />
                    <p className="eventsCardPreviewHeading">
                      {currEvent?.previewHeading
                        .split("CEO,")
                        .map((part, index) =>
                          index === 1 ? (
                            <>
                              <br />
                              CEO,{part}
                            </>
                          ) : (
                            part
                          )
                        )}
                    </p>
                    <button>Register Now</button>
                  </div>
                </a>
              )
            })}
          </Slider>
        </div>
      </div>
      <div className="calendlySection">
        <h1>Book a Custom Demo</h1>
        <p className="calendlySectionDesc">
          Get one-on-one guidance on how SHAPE can help your Business.{" "}
        </p>
        <div
          class="calendly-inline-widget"
          data-url="https://calendly.com/alikhan-shape/30min"
          style={{ minWidth: 320, height: 700 }}
        ></div>
      </div>
    </div>
  )
}
export default EventsCards
